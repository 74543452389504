<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-xl-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <div style='line-height: 70px;  margin-top: -18px; background: #f4f4f500; margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; margin-bottom: 8px;'>
        <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="display: inline;">
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary':state.paneBord==1,  'btn-custom-white':state.paneBord!=1 }"
              @click="state.paneBord=1"
              style='border: 1px solid'
            >
              Gestion des utilisateurs
            </a>
          </li>
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary':state.paneBord==2,  'btn-custom-white':state.paneBord!=2 }"
              @click="state.paneBord=2"
              style='border: 1px solid'
            >
              Gestion des producteurs et Fournisseurs
            </a>
          </li>
          <li class="nav-item" style="float: right;">
            <a
              class="btn btn-custom-red text-active-light me-6 ml-6"
              style='margin-left: 10px'
              v-if="state.paneBord==1"
              @click="newUser"
            >
              Créer un utilisateur
            </a>
          </li>
          <li class="nav-item" style="float: right;">
            <a
              class="btn btn-custom-red text-active-light me-6 ml-6"
              style='margin-left: 10px'
              v-if="state.paneBord==2"
              @click="newPersMorale"
            >
              Créer une personne morale
            </a>
          </li>
        </ul>
      </div>

      <Users v-if="state.paneBord==1" :key="state.count" @displayUser="editUser($event)" />
      <br>
      <Actors v-if="state.paneBord==2" :key="state.count"  @displayActor="editPersMorale($event)" />
    </div>
  </div>


 <el-dialog v-model="state.drawer" :title="`${ modalUser.uti_n_seq == 0 ? 'Création' : 'Modification' } d'un utilisateur`" width="800">
   <el-row :gutter="10" style="width: 100%">
        <el-col :span="16">
          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Identité</strong><span class="text-danger">*</span></span></div>

              <div class="col"> 
                <el-input v-model="modalUser.uti_c_prenom" placeholder="Spécifier le Prénom" class="m-2" style='float: left; width: calc(100%);' /> 
                <el-input v-model="modalUser.uti_c_nom" placeholder="Spécifier le nom" class="m-2" style='float: left; width: calc(100%);' /> 
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Service</strong></span></div>
              <div class="col"> <el-input v-model="modalUser.uti_c_service" placeholder="Spécifier le Service" class="m-2" /> </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Coordonnées</strong></span></div>
              <div class="col"> <el-input v-model="modalUser.uti_c_phone" placeholder="Spécifier le Téléphone" class="m-2" /> 
              <el-input v-model="modalUser.uti_c_mail" placeholder="Spécifier le Mail *" class="m-2" />
              <el-input v-model="modalUser.uti_c_mess_instant" placeholder="Spécifier l'adresse de messagerie instantanée" class="m-2" /></div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Paramètrage</strong></span></div>
              <div class="col"> 

                <el-select v-model="modalUser.uti_knum_role" class="m-2" style='width: 100%' placeholder="Selectionner un role applicatif" size="large" >
                  <el-option
                    v-for="item in state.lRoles"
                    :key="item.rol_n_seq"
                    :label="item.rol_c_libelle"
                    :value="item.rol_n_seq"
                  />
                </el-select>
                <!--
                <br>
                <el-checkbox class="m-2" label="Peut accèder à l'application Service Versant ?" />
                -->

              </div>
          </div>
        </el-col>
        <el-col :span="8" style='border-left: 1px solid #e3e6f0;'>
          <div class="p-5"><span style="font-size: 15px;"><strong>Fournisseurs / Producteurs liés</strong></span></div>
            
            <el-select filterable multiple clearable v-model="modalUser.producteur" class="m-2 new-bord prod-user" style='width: 100%; margin-top: 10px; max-height:400px; overflow-y: scroll;' placeholder="Selection de personnes morales" size="large" >
              <el-option v-for="item in state.lProducteurs" :key="item.act_n_seq" :label="`${'' + item.act_c_raison_sociale }`" :value="item.act_n_seq" >
                <div style='border-top: 1px solid #e3e3e3'>
                  <div>{{ item.act_c_raison_sociale }}</div>
                  <div  class="text-gray-500 fs-7" style="line-height: 10px; margin-bottom: 5px;" v-if="item.act_ark_c_nom2">
                    {{ item.act_ark_c_nom2 }}
                  </div>
                  <div  class="text-gray-500 fs-7" style="line-height: 10px; margin-bottom: 5px;" v-if="item.act_ark_c_nom3">
                    {{ item.act_ark_c_nom3 }}
                  </div>
                </div>
              </el-option>
            </el-select>

            <div v-if="getLengthProd==0" class="fs-7 px-5 text-danger" style="word-break: break-word;">Tant qu'aucune société n'est sélectionnée, cet utilisateur pourra accèder à toutes les personnes morales.</div>

            <!--
            <div class="px-5" style="max-height: 420px; overflow-y: auto; overflow-x: hidden;">
              <template v-for="item in state.lProducteurs" :key="item.act_n_seq" >
                <el-checkbox :label="item.act_c_raison_sociale" />
                <div class="mx-7 mb-3"><i>{{ item.act_c_categorie }}</i></div>
              </template>
          </div>
          -->
        </el-col>
   </el-row>



    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn btn-secondary" @click="state.drawer = false">Annuler</el-button>
        <el-button class="btn btn-primary" @click="addEditUser">{{ modalUser.uti_n_seq > 0 ? "Modifier" : "Créer" }} l'utilisateur</el-button>
      </span>
    </template>
  </el-dialog>


 <el-dialog v-model="state.drawer_morale" :title="`${ modalActeur.act_n_seq == 0 ? 'Création' : 'Modification' } d'une personne morale`" width="1000">
   <el-row :gutter="10" style="width: 100%">
        <el-col :span="16">
          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Raison sociale</strong></span></div>

              <div class="col"> 
                <el-input v-model="modalActeur.act_c_raison_sociale" placeholder="Spécifier la raison sociale" class="m-2 fs-3 bolder" /> 
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Type</strong></span></div>
              <div class="col" > 
                <select v-model="modalActeur.act_ark_n_type" class="form-select h-40px mt-2 m-2"  aria-label="Selection">
                  <option value="-1">Type inconnu</option>
                  <option value="1">Collectivité</option>
                  <option value="2">Personne</option>
                  <option value="3">Famille</option>
                </select>
                </div>
          </div>


          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Catégorie</strong></span></div>
              <div class="col"> <el-input v-model="modalActeur.act_c_categorie" placeholder="Spécifier la catégorie" class="m-2" /> </div>
          </div>


          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Coordonnées</strong></span></div>
              <div class="col"> <el-input v-model="modalActeur.act_c_phone" placeholder="Spécifier le Téléphone" class="m-2" /> 
              <el-input v-model="modalActeur.act_c_mail" placeholder="Spécifier le Mail" class="m-2" />
              <el-input v-model="modalActeur.act_c_adr" type="textarea" :rows="3" placeholder="Spécifier l'adresse" class="m-2" />
              <el-input v-model="modalActeur.act_ark_c_cp" placeholder="Spécifier le code postal" class="m-2" />
              <el-input v-model="modalActeur.act_ark_c_ville" placeholder="Spécifier la ville" class="m-2" />
              <el-input v-model="modalActeur.act_ark_c_pays" placeholder="Spécifier le pays" class="m-2" /></div>
          </div>
        </el-col>
        <el-col :span="8" style='border-left: 1px solid #e3e6f0;'>
          <div class="p-5"><span style="font-size: 15px;"><strong>Nom 2</strong></span></div>
          <el-input v-model="modalActeur.act_ark_c_nom2" placeholder="Spécifier le Service versant" class="m-2 mt-0" />

          <div class="p-5"><span style="font-size: 15px;"><strong>Nom 3</strong></span></div>
          <el-input v-model="modalActeur.act_ark_c_nom3" placeholder="Spécifier la direction" class="m-2 mt-0" />

          <div class="p-5"><span style="font-size: 15px;"><strong>Nom 4</strong></span></div>
          <el-input v-model="modalActeur.act_ark_c_nom4" placeholder="Spécifier la sous direction" class="m-2 mt-0" />

          <div class="p-5"><span style="font-size: 15px;"><strong>Responsable</strong></span></div>

          <el-select filterable  v-model="modalActeur.act_knum_responsable" class="m-2 new-bord" style='width: 100%; margin-top: 10px;' placeholder="Selectionner un responsable" size="large" >
            <el-option v-for="item in state.lUsers" :key="item.uti_n_seq" :label="`${item.uti_c_prenom} ${item.uti_c_nom}`" :value="item.uti_n_seq" >
                <div>{{ item.uti_c_prenom }} {{ item.uti_c_nom }}</div>
            </el-option>
          </el-select>

        </el-col>
   </el-row>



    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn btn-custom-white text-left" style="color: #000 !important;" @click="importJSON">Import JSON depuis le presse papier</el-button>
        <el-button class="btn btn-secondary" @click="state.drawer_morale = false">Annuler</el-button>
        <el-button class="btn btn-primary" @click="addEditPersMorale">{{ modalActeur.act_n_seq > 0 ? "Modifier" : "Créer" }} la personne morale</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import mAxiosApi from "@/api";
import Users from "@/views/still/admin/users.vue"
import Actors from "@/views/still/admin/actors.vue"
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: "customer-details",
  components: {
    Users,
    Actors,
  },
 
  setup() {

    const state = reactive({
      loaderEnabled: true,
      lProducteurs: [] as any,
      lUsers: [] as any,
      lRoles: [] as any,
      drawer: false,
      drawer_morale: false,
      count: 0,
      paneBord : 1,
      zUser: {} as any,
      reloadlst : 1,
    });

    const modalActeur= reactive({
      act_n_seq: 0,
      act_c_raison_sociale: '',
      act_ark_n_type: '',
      act_c_categorie: '',
      act_c_status: '',
      act_c_mail: '',
      act_c_phone: '',
      act_c_adr: '',
      act_d_import: '',
      act_ark_c_nom2: '',
      act_ark_c_nom3: '',	
      act_ark_c_nom4: '',
      act_knum_responsable : '',
      act_ark_c_cp: '',
      act_ark_c_ville: '',
      act_ark_c_pays: ''
    });

    const modalUser= reactive({
      uti_n_seq: 0,
      uti_c_service: "",
      uti_c_phone: '',
      uti_c_mail: '',
      uti_knum_role: '',
      uti_c_prenom: '',
      uti_c_nom : '',
      uti_c_mess_instant : '',
      uti_c_status: '',
      producteur: [],
    });

    onMounted(async () => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Administration de l'application", []);

      let myListLiv = await getAxios("/getSVProducteur");
      state.lProducteurs = myListLiv.results;
      
      myListLiv = await getAxios("/getSVRoles");
      state.lRoles = myListLiv.results;

      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;

      state.lUsers.forEach(element => {
        if (element.producteur) {
          element.producteur = element.producteur.split(",").map(function(e){return parseInt(e,10)});
        }
      });

      // console.log(state.lUsers);
      
      state.count =state.count + 1;
    });



    const newUser = () => {
      modalUser.uti_n_seq = 0;
      modalUser.uti_c_service = "",
      modalUser.uti_c_phone = '',
      modalUser.uti_c_mail = '',
      modalUser.uti_knum_role = '',
      modalUser.uti_c_prenom = '',
      modalUser.uti_c_nom = '',
      modalUser.uti_c_mess_instant = '',
      modalUser.uti_c_status = '',
      modalUser.producteur = [];
      state.drawer = true;
    }

    const editUser = (d) => {
      modalUser.uti_n_seq = d.uti_n_seq;
      modalUser.uti_c_service = d.uti_c_service,
      modalUser.uti_c_phone = d.uti_c_phone,
      modalUser.uti_c_mail = d.uti_c_mail,
      modalUser.uti_knum_role = d.uti_knum_role,
      modalUser.uti_c_prenom = d.uti_c_prenom,
      modalUser.uti_c_nom = d.uti_c_nom,
      modalUser.uti_c_mess_instant = d.uti_c_mess_instant,
      modalUser.uti_c_status = d.uti_c_status,
      modalUser.producteur = d.producteur,
      state.drawer = true;
    }

    const getLengthProd = computed(() => {
      if (modalUser && modalUser.producteur) {
        return modalUser.producteur.length;
      }
      return 0
    });


    const addEditUser = () => {
        // console.log("modalUser", modalUser);

        if (!modalUser.uti_c_prenom) {
          ElNotification({ title: 'Erreur', message: 'Le champ Prénom est obligatoire', type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_nom) {
          ElNotification({ title: 'Erreur', message: 'Le champ Nom est obligatoire', type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_mail) {
          ElNotification({ title: 'Erreur', message: 'Le champ Email est obligatoire', type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_knum_role) {
          ElNotification({ title: 'Erreur', message: 'Le champ Role est obligatoire', type: 'error', }); 
          return false;
        }

        mAxiosApi
          .post("/addEditUser", modalUser)
          .then( async() => {
            const mNotif = modalUser.uti_n_seq > 0 ? "Utilisateur modifié avec succès !" : "Utilisateur créé avec succès !";
            ElNotification({ title: 'Succès', message: mNotif, type: 'success', })
            state.count = state.count + 1
            state.drawer = false;
          })

    }
  
    const editPersMorale = (d) => {
      modalActeur.act_n_seq = d.act_n_seq;
      modalActeur.act_c_raison_sociale = d.act_c_raison_sociale;
      modalActeur.act_ark_n_type = d.act_ark_n_type;
      modalActeur.act_c_categorie = d.act_c_categorie;
      modalActeur.act_c_mail = d.act_c_mail;
      modalActeur.act_c_phone = d.act_c_phone;
      modalActeur.act_c_adr = d.act_c_adr;
      modalActeur.act_ark_c_nom2 = d.act_ark_c_nom2;
      modalActeur.act_ark_c_nom3 = d.act_ark_c_nom3;
      modalActeur.act_ark_c_nom4 = d.act_ark_c_nom4;
      modalActeur.act_knum_responsable = d.act_knum_responsable;

      state.drawer_morale = true;
    }

    const newPersMorale = () => {
      modalActeur.act_n_seq = 0;
      modalActeur.act_c_raison_sociale = "";
      modalActeur.act_ark_n_type = "";
      modalActeur.act_c_categorie = "";
      modalActeur.act_c_mail = "";
      modalActeur.act_c_phone = "";
      modalActeur.act_c_adr = "";
      modalActeur.act_ark_c_nom2 = "";
      modalActeur.act_ark_c_nom3 = "";
      modalActeur.act_ark_c_nom4 = "";
      modalActeur.act_knum_responsable = "";
      state.drawer_morale = true;
    }

    const addEditPersMorale = async () => {
      
      mAxiosApi
        .post("/addEditPersMorale", modalActeur)
        .then( async() => {
          const mNotif = modalActeur.act_n_seq > 0 ? "Acteur modifié avec succès !" : "Acteur créé avec succès !";
          ElNotification({ title: 'Succès', message: mNotif, type: 'success', })
          state.count = state.count + 1
          state.drawer_morale = false;
        })
    };

    const importJSON  = async() => {
      const jsonText = await navigator.clipboard.readText();
      try {
        let errorInJSON = false;
        try{
          let datas = JSON.parse(jsonText);

          if(!datas || !datas.act_c_raison_sociale) errorInJSON = true;

          if (!errorInJSON) {
            // if(datas.type == "")
            const responsable = datas.responsable ? state.lUsers.filter((el) => (el.uti_c_prenom + ' ' + el.uti_c_nom).toUpperCase() == datas.responsable.toUpperCase())[0].uti_n_seq : null
            const majType = datas.type.toUpperCase()
            let type = null as any
            if(majType == 'COLLECTIVITÉ') type = '1';
            if(majType == 'PERSONNE') type = '2';
            if(majType == 'FAMILLE') type = '3';

            modalActeur.act_c_raison_sociale = datas.act_c_raison_sociale
            modalActeur.act_ark_n_type = type
            modalActeur.act_c_categorie = datas.act_c_categorie
            modalActeur.act_c_mail = datas.act_c_mail
            modalActeur.act_c_phone = datas.act_c_phone
            modalActeur.act_c_adr = datas.act_c_adr
            modalActeur.act_ark_c_nom2 = datas.act_ark_c_nom2
            modalActeur.act_ark_c_nom3 = datas.act_ark_c_nom3
            modalActeur.act_ark_c_nom4 = datas.act_ark_c_nom4
            modalActeur.act_ark_c_ville = datas.act_ark_c_ville
            modalActeur.act_ark_c_cp = datas.act_ark_c_cp
            modalActeur.act_ark_c_pays = datas.act_ark_c_pays
            modalActeur.act_knum_responsable = responsable
          }

          } catch (er) {
          errorInJSON = true;
        }
        if (errorInJSON) {
          ElNotification({
            type: 'error',
            title: 'Erreur',
            message: 'Le JSON dans le presse papier est invalide.'
          })
        }
      } catch (error) {
        ElNotification({
          type: 'error',
          title: 'Erreur',
          message: 'Veuillez autoriser la lecture du presse papier dans les paramètres du site.'
        })
      }
    }

    return {
      state,
      modalActeur,
      editPersMorale,
      newPersMorale,
      addEditPersMorale,
      addEditUser,
      modalUser,
      newUser,
      editUser,    
      getLengthProd,  
      importJSON
    }
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
